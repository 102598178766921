@import url(//netdna.bootstrapcdn.com/font-awesome/3.2.1/css/font-awesome.css);
* {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

body {
  font-family: "PT Sans", sans-serif;
  background: #f3f2f0;
}

@font-face {
  font-family: "Futura Extra Bold";
  src: local("Futura Extra Bold"),
    url(/static/media/Futura_Extra_Bold.db7c22c7.otf) format("truetype");
}

.control-panel {
  font-size: 16px;
  color: black;
  background-color: white;
  border-radius: 5px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.clickable {
  cursor: pointer;
}

.disabled {
  cursor: not-allowed;
  color: lightgray;
}

/* .App {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 3rem;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
} */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.Login_Wrapper__2mc7C {
  height: 100vh;
  background-color: #e5e5e5;
  overflow: hidden;
  overflow-y: auto;
}
.Login_LoginContainer__2TmR3 {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-height: 100%;
  padding: 20px;
}

.Login_Login__2ADe5 {
  background-color: white;
  border-radius: 10px;
  padding: 30px;
  width: 90%;
  max-width: 450px;
  position: relative;
  box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.3);
}

.Login_ImgWrapper__v3nf3 {
  width: 80%;
  margin: 0 auto;
  padding: 20px;
}
.Login_ImgWrapper__v3nf3 img {
  width: 100%;
}

.Login_LoginTitle__3JTyX {
  font-size: 2em;
  text-align: left;
  line-height: 1.2em;
  margin-bottom: 40px;
}
.Login_LoginTitle__3JTyX span {
  background: -webkit-gradient(
    linear,
    left top, left bottom,
    color-stop(60%, rgba(255, 255, 255, 0)),
    color-stop(40%, rgba(0, 78, 124, 0.178))
  );
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 60%,
    rgba(0, 78, 124, 0.178) 40%
  );
}

/* ====FORM STYLING START====== */
.Login_InputGroup__1mnJA {
  position: relative;
  width: 80%;
  margin-bottom: 40px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.Login_Input__2B01B {
  font-size: 18px;
  font-family: inherit;
  padding: 10px 10px 10px 5px;
  display: block;
  width: 100%;
  border: none;
  border-bottom: 1px solid #757575;
  font-family: Arial, Helvetica, sans-serif;
}
.Login_Input__2B01B:focus {
  outline: none;
}
.Login_Phone__YOYkA {
  padding-left: 45px;
}
.Login_InputGroup__1mnJA i {
  position: absolute;
  font-size: 1.3em;
  top: 12px;
  left: -35px;
  color: #999;
  cursor: pointer;
}
.Login_MUIInputGroup__31dGQ {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2em;
}
.Login_InputError__3xKas {
  font-size: 0.85rem;
  color: #e0004d;
  margin-top: 0.5em;
}
/* LABEL ======= */
.Login_InputLabel__3ZpRg {
  color: #999;
  font-size: 18px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 5px;
  top: 10px;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}
/* active state */
.Login_Input__2B01B:focus ~ .Login_InputLabel__3ZpRg,
.Login_Input__2B01B:valid ~ .Login_InputLabel__3ZpRg {
  top: -20px;
  font-size: 14px;
  color: #003c71;
}
/* BOTTOM BARS ========= */
.Login_Bar__36kxa {
  position: relative;
  display: block;
  width: 100%;
}
.Login_Bar__36kxa:before,
.Login_Bar__36kxa:after {
  content: "";
  height: 2px;
  width: 0px;
  bottom: 1px;
  position: absolute;
  background: #003c71;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}
.Login_Bar__36kxa:before {
  left: 50%;
}
.Login_Bar__36kxa:after {
  right: 50%;
}
/* active state */
.Login_Input__2B01B:focus ~ .Login_Bar__36kxa:before,
.Login_Input__2B01B:focus ~ .Login_Bar__36kxa:after {
  width: 50%;
}

/* HIGHLIGHTER ============ */
.Login_Highlight__1OYzI {
  position: absolute;
  height: 60%;
  width: 100px;
  top: 25%;
  left: 0;
  pointer-events: none;
  opacity: 0.5;
}
/* active state */
.Login_Input__2B01B:focus ~ .Login_Highlight__1OYzI {
  animation: Login_inputHighlighter__1NG13 0.3s ease;
  -webkit-animation: Login_inputHighlighter__1NG13 0.3s ease;
  -moz-animation: Login_inputHighlighter__1NG13 0.3s ease;
}
/* Animations */
@keyframes Login_inputHighlighter__1NG13 {
  from {
    background: #003c71;
  }
  to {
    width: 0;
    background: transparent;
  }
}
@-webkit-keyframes Login_inputHighlighter__1NG13 {
  from {
    background: #003c71;
  }
  to {
    width: 0;
    background: transparent;
  }
}

/* Indicatif ====== */
.Login_Indicatif__H0S93 {
  position: absolute;
  font-size: 18px;
  top: 24%;
  left: 0;
  opacity: 0;
  font-family: Arial, Helvetica, sans-serif;
}
/* active state */
.Login_Input__2B01B:focus ~ .Login_Indicatif__H0S93,
.Login_Input__2B01B:valid ~ .Login_Indicatif__H0S93 {
  opacity: 1;
}

/* ========= CGU CHECKBOX =========== */

.Login_CGUGroup__2kx53 input[type="checkbox"] {
  display: none;
}
.Login_CGUGroup__2kx53 input[type="checkbox"] + label:before {
  font-family: FontAwesome;
  display: inline-block;
}

.Login_CGUGroup__2kx53 input[type="checkbox"]:checked + label:before {
  content: "\f046";
}
.Login_CGUGroup__2kx53 input[type="checkbox"]:checked + label:before {
  letter-spacing: 8px;
}

.Login_CGUGroup__2kx53 input[type="checkbox"] + label:before {
  content: "\f096";
}
.Login_CGUGroup__2kx53 input[type="checkbox"] + label:before {
  letter-spacing: 10px;
}

/* CGU Link ====== */
.Login_CGUGroup__2kx53 label {
  color: #828489;
  font-size: 0.9em;
}
.Login_CGUGroup__2kx53 span {
  cursor: pointer;
  /* font-weight: 700; */
  background: -webkit-gradient(
    linear,
    left top, left bottom,
    color-stop(60%, rgba(255, 255, 255, 0)),
    color-stop(40%, rgba(0, 78, 124, 0.178))
  );
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 60%,
    rgba(0, 78, 124, 0.178) 40%
  );
}

/* ======SUBMIT BUTTON ====== */
.Login_SubmitButtonContainer__2vKhs {
  margin-top: 40px;
}

/* Anon link */
.Login_AnonLogin__3FF9C {
  margin-top: 1em;
  text-align: center;
}

.RefInfo_RefInfoTitle__1PgkU {
  display: flex;
}
.RefInfo_RefInfoTitle__1PgkU h3 {
  margin-left: 8px;
}

.RefInfo_RefWrapper__16_C4 img {
  width: 100%;
  margin-top: 15px;
}

.CGU_CGUTitle__1F9Gq {
  text-align: center;
}

.CGU_CGUWrapper__1pOsM {
  margin-top: 20px;
  height: 50vh;
  overflow-y: scroll;
}

.CGU_Article__26SrW {
  margin-bottom: 1em;
}

.CGU_ArticleTitle__1-E0F {
  margin-bottom: 0.5em;
}
.CGU_ArticleSubtitle__3PVGp {
  margin-bottom: 0.5em;
}
.CGU_ArticleContent__2CH1n {
  margin-bottom: 0.5em;
}
.CGU_ArticleContent__2CH1n p {
  margin-bottom: 0.3em;
}

.Modal_Modal__3YXpU {
  position: fixed;
  z-index: 500;
  background: white;
  border-radius: 9px;
  width: 90%;
  padding: 16px;
  left: 5%;
  top: 30%;
  box-sizing: border-box;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

@media (min-width: 600px) {
  .Modal_Modal__3YXpU {
    width: 500px;
    left: calc(50% - 250px);
  }
}

.Backdrop_Backdrop__2DcxQ {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  /* background-color: rgba(229, 229, 229, 0.8); */
  background-color: rgba(0, 0, 0, 0.5);
}

.SubmitButton_SubmitButton__3YjLl {
  display: flex;
  margin: 0 auto;
  align-items: center;
  padding: 1em 1em;
  min-width: 10em;
  border: 0;
  outline: 0;
  font-size: 0.9rem;
  text-align: center;
  /* Primaire Rouge 1 */
  background: #e0004d;
  /* 6dp elevation */
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2), 0px 1px 18px rgba(0, 0, 0, 0.12),
    0px 6px 10px rgba(0, 0, 0, 0.14);
  border-radius: 7px;
  cursor: pointer;
  color: white;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  position: relative;
}

.SubmitButton_SubmitButton__3YjLl:active {
  box-shadow: inset 1px 1px 2px #babecc, inset -1px -1px 2px #fff;
}

.SubmitButton_SubmitButton__3YjLl:disabled {
  background-color: gray;
  color: #ccc;
  cursor: not-allowed;
}

/* Invalid state */
.SubmitButton_Invalid__2MpFZ i {
  -webkit-transform: translate(-30vw, 11vh);
          transform: translate(-30vw, 11vh);
  -webkit-transition: all linear 0.2s;
  transition: all linear 0.2s;
  opacity: 0;
}
.SubmitButton_Invalid__2MpFZ span {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  /* transition: all linear 0.2s; */
}

/* Valid state */
.SubmitButton_Valid__2Dv1S i {
  color: #ffc300;
  font-size: 1.4em;
  margin-left: 0.4em;
  -webkit-transition: all linear 0.2s;
  transition: all linear 0.2s;
  opacity: 1;
}

/* Loading state */
.SubmitButton_Clicked__21e_8 i {
  -webkit-transform: translate(100vw, -90vh);
          transform: translate(100vw, -90vh);
  -webkit-transition: all linear 1.3s;
  transition: all linear 1.3s;
}

.CircularLoading_CircularLoading__3CIgt {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.Home_SubNavbar__16yOh {
  margin: 0 1.5rem;
  display: flex;
  justify-content: space-between;
}
.Home_SubNavbar__16yOh p {
  padding: 0.625rem 1.25rem;
  border-radius: 30px;
  font-weight: 700;
  font-size: 0.875rem;
}
.Home_ActionButton__O2Bbp p {
  color: white;
  background: #3b4578;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.4);
  border-radius: 30px;
  cursor: pointer;
  position: relative;
}
.Home_ActionButtonSvg__fxTep {
  display: none;
}
/* large tablets & laptops */
@media screen and (min-width: 600px) {
}

/* large tablets & laptops */
@media screen and (min-width: 768px) {
  .Home_SubNavbar__16yOh {
    margin: 0 4rem;
  }
  .Home_ActionButtonSvg__fxTep {
    display: block;
    width: 15rem;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    position: absolute;
    top: -35rem;
    right: 10rem;
    right: 3rem;
  }
  .Home_Footer__2uteD {
    width: 100%;
    margin-top: 6rem;
    padding-right: 5rem;
    display: flex;
    justify-content: flex-end;
  }
}

/* large tablets & laptops */
@media screen and (min-width: 1200px) {
  .Home_SubNavbar__16yOh {
    margin: 0 11rem;
  }
  .Home_ActionButtonSvg__fxTep {
    right: 10rem;
  }
}

/* Very large screens */
@media screen and (min-width: 1500px) {
}

.HomeToolbar_Header__10XyN {
  background-color: #f3f2f0;
  padding: 2rem 1.5rem;
}

.HomeToolbar_Toolbar__ijPa_ {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.HomeToolbar_Title__jbWYC {
  margin-top: 1.5rem;
  font-family: "Nunito Sans";
  line-height: 1.7rem;
}
.HomeToolbar_Title__jbWYC h3 {
  font-size: 1.8rem;
  color: #292f33;
  text-transform: uppercase;
  font-weight: 900;
}
.HomeToolbar_Title__jbWYC h4 {
  font-size: 1.6rem;
  font-weight: 600;
  color: #5b5855;
}

.HomeToolbar_Description__1etSq {
  color: #5b5855;
  margin-top: 2rem;
  font-weight: 400;
  font-size: 1.125rem;
}

/* large tablets & laptops */
@media screen and (min-width: 768px) {
  .HomeToolbar_Header__10XyN {
    padding: 2rem 4rem;
  }
  .HomeToolbar_Title__jbWYC h3 {
    font-size: 1.8rem;
  }
  .HomeToolbar_Title__jbWYC {
    display: flex;
  }
  .HomeToolbar_Title__jbWYC h4 {
    margin-left: 1rem;
  }
  .HomeToolbar_Description__1etSq {
    width: 23rem;
  }
}

/* large tablets & laptops */
@media screen and (min-width: 1200px) {
  .HomeToolbar_Header__10XyN {
    padding: 2rem 11rem;
  }

  .HomeToolbar_Title__jbWYC h3 {
    font-size: 2rem;
  }
}

.HomeFooter_Footer__1jMIG {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.HomeFooter_LogoContainer__268sq {
  width: 15em;
  margin: 1em 0;
}
.HomeFooter_LogoContainer__268sq img {
  width: 100%;
}
.HomeFooter_LegalLinks__3ouIm {
  display: flex;
  margin-top: 1em;
  margin-bottom: 3rem;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0.5rem;
}
.HomeFooter_LegalLinks__3ouIm p {
  cursor: pointer;
  margin: 0.5rem;
  font-size: 0.8rem;
  color: #828282;
  padding: 0.8rem 1rem;
  background-color: #f3f2f0;
  border-radius: 10px;
}
.HomeFooter_Copyrights__1NAkk {
  margin: 1em 0;
  color: #5b5855;
  text-align: center;
  font-size: 0.8rem;
}
.HomeFooter_Copyrights__1NAkk span {
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 900;
  font-family: "Nunito Sans", sans-serif;
}

.HomeFooter_ImmobiliereLogo__3YxLV {
  width: 12rem;
  margin-bottom: 1rem;
  margin-left: auto;
  margin-right: auto;
}

.ViewSelector_ViewSelector__2ilw5 {
  display: flex;
  justify-content: space-between;
}
.ViewSelector_ViewSelector__2ilw5 p {
  border-radius: 30px;
  cursor: pointer;
  box-sizing: border-box;
}
.ViewSelector_UnselectedView__aezlf {
  color: #292f33;
  background: #f3f2f0;
  border: 1px solid #3b4578;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.4);
}
.ViewSelector_SelectedView__2oWee {
  background: #3b4578;
  color: white;
  border: 1px solid #3b4578;
}
/* large tablets & laptops */
@media screen and (min-width: 600px) {
}

/* large tablets & laptops */
@media screen and (min-width: 768px) {
  .ViewSelector_ViewSelector__2ilw5 {
    width: 15rem;
  }
}

/* large tablets & laptops */
@media screen and (min-width: 1200px) {
}

/* Very large screens */
@media screen and (min-width: 1500px) {
}

/* large tablets & laptops */
@media screen and (min-width: 600px) {
}

/* large tablets & laptops */
@media screen and (min-width: 768px) {
}

/* large tablets & laptops */
@media screen and (min-width: 1200px) {
}

/* Very large screens */
@media screen and (min-width: 1500px) {
}

.CalendarDesktop_Calendar__3BRhh {
  margin: 4rem 4rem;
}

.CalendarDesktop_CalendarTitle__UAcMW {
  font-size: 1.25rem;
  color: #292f33;
}
.CalendarDesktop_CalendarTitle__UAcMW span {
  font-weight: 700;
}

.CalendarDesktop_Main__2bXkQ {
  display: flex;
  margin-top: 2rem;
}

.CalendarDesktop_CardsList__1_xwn {
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
}
.CalendarDesktop_Modal__1gscy {
  min-width: 21rem;
  max-width: 21rem;
}
.CalendarDesktop_PlanSchematique__2Lb7i {
  /* background-color: white; */
  /* border-radius: 15px; */
  width: 70%;
  margin: 0 auto;
  margin-top: 2rem;
}

/* large tablets & laptops */
@media screen and (min-width: 1200px) {
  .CalendarDesktop_Calendar__3BRhh {
    margin: 4rem 11rem;
  }
}

/* Very large screens */
@media screen and (min-width: 1500px) {
}

.CalendarCard_Card__ss4Io {
  max-width: 15rem;
  min-width: 15rem;
  max-height: 10rem;
  padding: 1rem;
  margin-bottom: 2rem;
  margin-right: 2rem;
  background: #ffffff;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 18px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
}
.CalendarCard_Emoji__dbCj3 {
  font-size: 2rem;
  margin: auto;
}
.CalendarCard_Card__ss4Io p {
  margin-top: 0.5rem;
  font-size: 1rem;
  text-align: center;
}
.CalendarCard_SelectedCard__3Fi7M {
  background: #3b4578;
  border-radius: 18px;
  color: white;
  max-width: 15rem;
  min-width: 15rem;
  max-height: 10rem;
  padding: 1rem;
  margin-bottom: 2rem;
  margin-right: 2rem;
  cursor: pointer;
  display: flex;
  flex-direction: column;
}
.CalendarCard_SelectedCard__3Fi7M p {
  margin-top: 0.5rem;
  font-size: 1rem;
  text-align: center;
}

/* large tablets & laptops */
@media screen and (min-width: 600px) {
}

/* large tablets & laptops */
@media screen and (min-width: 768px) {
}

/* large tablets & laptops */
@media screen and (min-width: 1200px) {
}

/* Very large screens */
@media screen and (min-width: 1500px) {
}

.CalendarModal_CalendarModal__1i-8a {
  background: #ffffff;
  border-radius: 18px;
  height: 30rem;
  overflow-y: scroll;
  /*-ms-overflow-style: none;  IE and Edge */
  /*scrollbar-width: none;  Firefox */
}
/* .CalendarModal::-webkit-scrollbar {
  display: none;
} */

.CalendarModal_StepTitle__1C_IB {
  background-color: #3b4578;
  color: white;
  border-radius: 18px 18px 0 0;
  display: flex;
  padding: 1rem;
  font-size: 1.18rem;
  justify-content: center;
  align-items: center;
  position: -webkit-sticky;
  position: sticky;
}
.CalendarModal_TitleEmoji__3mJd8 {
  font-size: 3rem;
  margin-right: 1rem;
  margin-top: -0.5rem;
}
.CalendarModal_Substeps__2W1pk {
  padding: 1rem 1rem;
}
.CalendarModal_Substep__1cbM2 {
  color: #292f33;
  font-size: 1rem;
  margin-bottom: 2rem;
}
.CalendarModal_Description__NGAqU {
  display: flex;
  margin-bottom: 0.3rem;
}
.CalendarModal_Emoji__3pUzE {
  font-size: 2rem;
  margin-right: auto;
  margin-left: auto;
}

.CalendarModal_Plan__2Ghqq {
  margin-bottom: 2rem;
  text-align: center;
}

/* large tablets & laptops */
@media screen and (min-width: 600px) {
}

/* large tablets & laptops */
@media screen and (min-width: 768px) {
}

/* large tablets & laptops */
@media screen and (min-width: 1200px) {
}

/* Very large screens */
@media screen and (min-width: 1500px) {
}

.CalendarMobile_Calendar__15TPe {
  margin: 4rem 4rem;
}

.CalendarMobile_CalendarTitle__1cASC {
  font-size: 1.25rem;
  color: #292f33;
}
.CalendarMobile_CalendarTitle__1cASC span {
  font-weight: 700;
}

.CalendarMobile_Main__1eCDA {
  display: flex;
  margin-top: 2rem;
}

.CalendarMobile_CardsList__34hbq {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.CalendarMobile_Modal__2LG2g {
  width: 80%;
  outline: none;
}
.CalendarMobile_PlanSchematique__14LmI {
  width: 100%;
}

/* large tablets & laptops */
@media screen and (min-width: 1200px) {
  .CalendarMobile_Calendar__15TPe {
    margin: 4rem 11rem;
  }
}

/* Very large screens */
@media screen and (min-width: 1500px) {
}

.Projet_Projet__2K45h {
  padding: 2rem 1.5rem;
  margin-bottom: 2rem;
}
.Projet_Projet__2K45h p {
  margin-bottom: 1.5rem;
  color: #5b5855;
}

.Projet_Articles__3G5zu {
  margin-bottom: 1.5rem;
}
.Projet_ArticlesHeader__3LcW4 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.Projet_Description__3nWKZ p {
  text-align: justify;
}

.Projet_Description__3nWKZ ul {
  margin-bottom: 1.5rem;
  margin-top: -0.5rem;
  margin-left: 2rem;
}
.Projet_Description__3nWKZ ul li {
  margin-bottom: 0.4rem;
  color: #5b5855;
  font-weight: bold;
}

.Projet_ActionButton__3I74d {
  display: none;
}
.Projet_ActionButton__3I74d p,
.Projet_ActionButtonOutside__2WPPX p {
  padding: 0.625rem 1.25rem;
  border-radius: 30px;
  font-weight: 700;
  font-size: 0.875rem;
  color: white;
  background: #3b4578;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.4);
  border-radius: 30px;
  cursor: pointer;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 auto;
  margin-top: 4rem;
}
.Projet_ActionButtonSvg__2p0K2 {
  display: none;
}

.Projet_DescriptionSvg__3G7J3 {
  max-width: 20rem;
  margin: 0 auto;
}

.Projet_VisiteVirtuelle__3l6Is {
  text-align: center;
  margin-top: -2rem;
  margin-bottom: 2rem;
}

/* large tablets & laptops */
@media screen and (min-width: 600px) {
}

/* large tablets & laptops */
@media screen and (min-width: 768px) {
  .Projet_Projet__2K45h {
    display: flex;
    flex-flow: row;
    padding: 4rem 4rem;
  }
  .Projet_Description__3nWKZ {
    margin-right: 5rem;
    padding-top: 3rem;
  }
  .Projet_DescriptionSvg__3G7J3 {
    display: none;
  }
  .Projet_DescriptionSection__3FFqE {
    position: relative;
  }
  .Projet_Description__3nWKZ {
    /* margin-left: 13vw; */
    margin-right: 5vw;
  }

  .Projet_ActionButton__3I74d {
    display: none;
  }

  .Projet_BackgroundShape__1NyTi {
    position: absolute;
    width: 37vw;
    height: 37vw;
    border-radius: 65% 37% 35% 64% / 58% 55% 37% 41%;
    background-color: #e3e7f1;
    /* background-color: lightcoral; */
    z-index: -2;
    right: -4rem;
    top: -4rem;
  }
}

/* large tablets & laptops */
@media screen and (min-width: 1200px) {
  .Projet_Projet__2K45h {
    padding: 4rem 11rem;
    padding-right: 5rem;
  }

  .Projet_DescriptionSection__3FFqE {
    position: relative;
  }
  .Projet_Description__3nWKZ {
    margin-left: 13vw;
    margin-right: 5vw;
  }
  .Projet_DescriptionSvg__3G7J3 {
    display: block;
    width: 25vw;
    position: absolute;
    left: -11rem;
    top: -3rem;
    z-index: -1;
  }

  .Projet_ActionButton__3I74d {
    display: block;
    position: relative;
  }

  .Projet_ActionButtonSvg__2p0K2 {
    display: block;
    width: 15rem;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;
    overflow: hidden;
  }
  .Projet_ActionButtonSvg__2p0K2::before {
    content: "";
    -webkit-transform: translate(-50%, 50%);
            transform: translate(-50%, 50%);
    position: absolute;
    height: 100%;
    width: 100%;
  }

  .Projet_BackgroundShape__1NyTi {
    position: absolute;
    width: 37vw;
    height: 37vw;
    border-radius: 65% 37% 35% 64% / 58% 55% 37% 41%;
    background-color: #e3e7f1;
    /* background-color: lightcoral; */
    z-index: -2;
    right: -4rem;
    top: -4rem;
  }
}

/* Very large screens */
@media screen and (min-width: 1500px) {
}

.ArticleModal_ArticleModal__QndXT {
  padding: 0;
  border-radius: 10px;

  padding: 1rem;
  outline: none;
  background-color: white;
  outline: none;
  overflow: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.ArticleModal_ArticleModal__QndXT::-webkit-scrollbar {
  display: none;
}

.ArticleModal_ModalContent__Da0y8 {
  width: 80%;
  outline: none;
}

/* large tablets & laptops */
@media screen and (min-width: 600px) {
}

/* large tablets & laptops */
@media screen and (min-width: 768px) {
  .ArticleModal_ModalContent__Da0y8 {
    width: 50%;
  }
}

/* large tablets & laptops */
@media screen and (min-width: 1200px) {
  .ArticleModal_ModalContent__Da0y8 {
    width: 40%;
  }
}

/* Very large screens */
@media screen and (min-width: 1500px) {
}

.Articles_ArticleCard__2ly_G {
  background: #ffffff;
  padding: 1rem;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.4);
  border-radius: 18px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1.5rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  max-width: 22rem;
}
.Articles_ArticleCard__2ly_G p {
  margin: 0;
  color: #292f33;
  font-size: 1rem;
}
.Articles_Emoji__11Uj- {
  margin-right: 0.5rem;
  font-size: 1.8rem;
}
.Articles_DeleteButton__1pRg0 {
  position: absolute;
  top: 0.5rem;
  right: 1rem;
}

/* large tablets & laptops */
@media screen and (min-width: 600px) {
}

/* large tablets & laptops */
@media screen and (min-width: 768px) {
  .Articles_ArticleCard__2ly_G {
    min-width: 23rem;
  }
}

/* large tablets & laptops */
@media screen and (min-width: 1200px) {
  .Articles_ArticleCard__2ly_G {
    min-width: 20rem;
  }
}

/* Very large screens */
@media screen and (min-width: 1500px) {
}

.UploadDocument_AddButton__2U21z p {
  background: #ee9834;
  color: white;
  border: 1px solid #ee9834;
  cursor: pointer;
  padding: 0.625rem 1.25rem;
  border-radius: 30px;
  font-weight: 700;
  font-size: 0.875rem;
}
.UploadDocument_ModalContent__1kG4y {
  background-color: #f3f2f0;
  padding: 2rem;
  border-radius: 10px;
  width: 30rem;
  outline: none;
}
.UploadDocument_QuestionTitle__3enCB {
  margin-top: 0.5em;
  display: flex;
  /* justify-content: center; */
  align-items: center;
}
.UploadDocument_QuestionTitle__3enCB p {
  color: #2a2f39;
  margin-left: 0.4em;
}
.UploadDocument_TitleField__3D5-T {
  margin-top: 1rem;
}
.UploadDocument_CloudIcon__2wE0i {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 0.6rem;
  justify-content: center;
}
.UploadDocument_Buttons__NZiPm {
  margin-top: 2rem;
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 768px) {
  .UploadDocument_ModalContent__1kG4y {
    width: 80%;
    outline: none;
  }
}

.ProfileSetup_Wrapper__1Q6vt {
  height: 100vh;
  background-color: #e5e5e5;
  overflow: hidden;
  overflow-y: auto;
}
.ProfileSetup_ProfileSetupContainer__1WuO4 {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-height: 100%;
  padding: 20px;
}

.ProfileSetup_ProfileSetup__1XUNM {
  background-color: white;
  border-radius: 10px;
  padding: 30px;
  width: 90%;
  max-width: 450px;
  position: relative;
  box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.3);
}

.ProfileSetup_ImgWrapper__2s2CT {
  width: 60%;
  margin: 0 auto;
  padding-bottom: 20px;
}
.ProfileSetup_ImgWrapper__2s2CT img {
  width: 100%;
}

.ProfileSetup_Title__2AajA {
  font-size: 1.5em;
  text-align: left;
  line-height: 1.2em;
  margin-bottom: 10px;
}
.ProfileSetup_Title__2AajA span {
  background: -webkit-gradient(
    linear,
    left top, left bottom,
    color-stop(60%, rgba(255, 255, 255, 0)),
    color-stop(40%, rgba(0, 78, 124, 0.178))
  );
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 60%,
    rgba(0, 78, 124, 0.178) 40%
  );
}
.ProfileSetup_CircularProgress__UzhgF {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.ProfileSetup_QuestionContainer__2Ok8D {
  height: 15rem;
  margin-bottom: 3.5rem;
}

/* START =========== Buttons */
.ProfileSetup_ButtonsContainer__E1AC4 {
  position: absolute;
  left: 0;
  bottom: 2rem;
  right: 0;
  text-align: center;
}
.ProfileSetup_Button__1jg1S {
  display: inline-block;
  margin: 0 1rem;
}
.ProfileSetup_SubmitButton__3-MtJ {
  line-height: 0;
  width: auto;
  padding: 0 12px;
  height: 48px;
  margin: 0 8px;
  font-size: 1.2rem;

  background: #ebecf0;
  border: 1px solid rgba(255, 255, 255, 0.7);
  box-sizing: border-box;
  box-shadow: 5px 5px 10px #a6abbd, -5px -5px 10px #fafbff;
  border-radius: 10px;

  outline: none;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}

.NextButton_NextButton__3k0wb {
  line-height: 0;
  width: 58px;
  height: 58px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5em;
  outline: none;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  color: #777;
  background: #3d9ec73f;
  border: none;
  border-radius: 50px;
}

.NextButton_NextButton__3k0wb:hover {
  background-color: #3d9dc7;
  color: white;
}

.NextButton_NextButton__3k0wb:active {
  background-color: #3d9dc7;
  color: white;
  box-shadow: inset 1px 1px 2px #80012d, inset -1px -1px 2px #d6929850;
}
.NextButton_NextButton__3k0wb i {
  padding-left: 2px;
}

.BackButton_BackButton__L2JQb {
  line-height: 0;
  width: 58px;
  height: 58px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5em;
  outline: none;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  color: #777;
  background: #3d9ec73f;
  border: none;
  border-radius: 50px;
}

.BackButton_BackButton__L2JQb:hover {
  background-color: #3d9dc7;
  color: white;
}

.BackButton_BackButton__L2JQb:active {
  background-color: #3d9dc7;
  color: white;
  box-shadow: inset 1px 1px 2px #80012d, inset -1px -1px 2px #d6929850;
}
.BackButton_BackButton__L2JQb i {
  padding-right: 2px;
}

.CircularProgressBar_Circle__15UCk {
  position: relative;
  width: 50px;
  height: 50px;
  border: 4px solid #c9d100;
  box-sizing: border-box;
  border-radius: 50%;
  /* animation-name: progress;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  animation-timing-function: linear; */
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.CircularProgressBar_Circle__15UCk::after {
  content: "";
  position: absolute;
  top: -21px;
  left: -21px;
  width: 180px;
  height: 180px;
  border: 22px solid #fff;
  box-sizing: border-box;
  border-radius: 50%;
  -webkit-clip-path: polygon(50% 50%, 50% 0, 50% 0, 50% 0, 50% 0, 50% 0, 50% 0);
          clip-path: polygon(50% 50%, 50% 0, 50% 0, 50% 0, 50% 0, 50% 0, 50% 0);
  /* animation-name: progress_after;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  animation-timing-function: linear; */
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.CircularProgressBar_Etape__2eFtR {
  margin-top: 0.35em;
  font-weight: bold;
  position: absolute;
  width: 100%;
  text-align: center;
  font-size: 1.5em;
}

/* SART ------------Basic Input--------------- */
.Input_InputGroup__1Rbnk {
  position: relative;
  width: 80%;
  margin-bottom: 40px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.Input_Input__1a_e3 {
  font-size: 18px;
  font-family: inherit;
  padding: 10px 10px 10px 5px;
  display: block;
  width: 100%;
  border: none;
  border-bottom: 1px solid #757575;
  font-family: Arial, Helvetica, sans-serif;
}
.Input_Input__1a_e3:focus {
  outline: none;
}
.Input_Phone__24HL1 {
  padding-left: 35px;
}
.Input_InputGroup__1Rbnk i {
  position: absolute;
  font-size: 1.3em;
  top: 12px;
  left: -35px;
  color: #999;
  cursor: pointer;
}
/* LABEL ======= */
.Input_InputLabel__1f3Zt {
  color: #999;
  font-size: 18px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 5px;
  top: 10px;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}
/* active state */
.Input_Input__1a_e3:focus ~ .Input_InputLabel__1f3Zt,
.Input_Input__1a_e3:valid ~ .Input_InputLabel__1f3Zt {
  top: -20px;
  font-size: 14px;
  color: #e31869;
}
/* BOTTOM BARS ========= */
.Input_Bar__2iDQW {
  position: relative;
  display: block;
  width: 100%;
}
.Input_Bar__2iDQW:before,
.Input_Bar__2iDQW:after {
  content: "";
  height: 2px;
  width: 0px;
  bottom: 1px;
  position: absolute;
  background: #e31869;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}
.Input_Bar__2iDQW:before {
  left: 50%;
}
.Input_Bar__2iDQW:after {
  right: 50%;
}
/* active state */
.Input_Input__1a_e3:focus ~ .Input_Bar__2iDQW:before,
.Input_Input__1a_e3:focus ~ .Input_Bar__2iDQW:after {
  width: 50%;
}

/* HIGHLIGHTER ============ */
.Input_Highlight__35VNA {
  position: absolute;
  height: 60%;
  width: 100px;
  top: 25%;
  left: 0;
  pointer-events: none;
  opacity: 0.5;
}
/* active state */
.Input_Input__1a_e3:focus ~ .Input_Highlight__35VNA {
  animation: Input_inputHighlighter__2YvDA 0.3s ease;
  -webkit-animation: Input_inputHighlighter__2YvDA 0.3s ease;
  -moz-animation: Input_inputHighlighter__2YvDA 0.3s ease;
}
/* Animations */
@keyframes Input_inputHighlighter__2YvDA {
  from {
    background: #e31869;
  }
  to {
    width: 0;
    background: transparent;
  }
}
@-webkit-keyframes Input_inputHighlighter__2YvDA {
  from {
    background: #e31869;
  }
  to {
    width: 0;
    background: transparent;
  }
}

/* Indicatif ====== */
.Input_Indicatif__17tyZ {
  position: absolute;
  font-size: 18px;
  top: 24%;
  left: 0;
  opacity: 0;
  font-family: Arial, Helvetica, sans-serif;
}
/* active state */
.Input_Input__1a_e3:focus ~ .Input_Indicatif__17tyZ,
.Input_Input__1a_e3:valid ~ .Input_Indicatif__17tyZ {
  opacity: 1;
}

/* START -----------Radio Button----------- */

.Input_RadioFormElement__3ACti {
  /* margin-top: 10px; */
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.Input_RadioFormElement__3ACti::-webkit-scrollbar {
  display: none; /* Safari & Chrome*/
}

.Input_RadioDivElement__3o66B {
  flex: 0 0 auto;
  margin: 20px 0;
  margin-right: 20px;
  width: 60px;
  height: 60px;
  position: relative;
}
.Input_RadioDivElement__3o66B:first-child {
  margin-left: 10vw;
}

.Input_RadioDivElement__3o66B input {
  opacity: 0;
}

.Input_RadioDivElement__3o66B label {
  width: 100%;
  height: 100%;
  font-size: 1em;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  text-align: center;
  line-height: 1em;
  background: #ebecf0;
  border: 1px solid rgba(255, 255, 255, 0.4);
  box-sizing: border-box;
  /* OmbreOUT */
  box-shadow: 5px 5px 10px #a6abbd, -5px -5px 10px #fafbff;
  border-radius: 10px;
  cursor: pointer;
}
.Input_RadioDivElement__3o66B span {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.Input_RadioDivElement__3o66B input[type="radio"]:checked + label {
  box-shadow: inset 1px 1px 2px #babecc, inset -1px -1px 2px #fff;
  color: #e31869;
  font-weight: bold;
}

/* END -----------Radio Button----------- */

/* Small tablets */

.QuestionTwo_QuestionTwo__VRxdr {
  margin: 1.3em 0;
  text-align: center;
}
.QuestionTwo_QuestionTwo__VRxdr p {
  margin: 20px;
  font-size: 1.1rem;
}

.Radio_FormTitle__P3oTS {
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 1.1rem;
  text-align: center;
}

.Radio_Form__21fQa {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.Radio_FormElement__3IV0m {
  margin: 5px 5px;
  width: 3.2em;
  height: 3.2em;
  position: relative;
}

.Radio_FormElement__3IV0m input[type="radio"] {
  display: none;
}

.Radio_FormElement__3IV0m label {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  border-radius: 15%;
  border: none;
  background-color: #3d9ec756;
  color: #777;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.Radio_FormElement__3IV0m input[type="radio"]:checked + label {
  background-color: #3d9dc7;
  color: white;
}

/* .Travaux {
  overflow-x: hidden;
} */
.Travaux_SubHeader__2sM2i {
  background-color: #ee9834;
  padding: 1rem 0;
  margin-top: -0.1rem;
}
.Travaux_HomeLink__2ptR0 {
  color: white;
  display: flex;
  justify-content: center;
}

.Travaux_HomeLink__2ptR0 a {
  color: white;
  font-size: 0.9rem;
  margin-left: 0.4rem;
  text-decoration: underline;
}
.Travaux_Main__1VA3N {
  padding: 0 0.2em;
  margin-bottom: 1rem;
}
.Travaux_CardListTitle__r6wea {
  margin-top: 3rem;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.Travaux_CardListTitle__r6wea p {
  font-size: 1.2rem;
  text-align: center;
  color: #828489;
  margin-bottom: 1rem;
}
.Travaux_CardListTitle__r6wea span {
  font-size: 1.2rem;
  font-weight: bold;
  color: #292f33;
}
.Travaux_Travaux__3mQTr {
  /* margin-bottom: 4em; */
}

/* large tablets & laptops */
@media screen and (min-width: 768px) {
  .Travaux_SubHeader__2sM2i {
    padding: 1rem 4rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .Travaux_CardListTitle__r6wea {
    flex-direction: row;
    justify-content: space-between;
  }
  .Travaux_CardListTitle__r6wea p {
    font-size: 1.4rem;
  }
  .Travaux_CardListTitle__r6wea span {
    font-size: 1.4rem;
  }
  .Travaux_Travaux__3mQTr {
    margin: 0 4rem;
    margin-top: 1em;
    /* margin-bottom: 4em; */
  }
  .Travaux_MainCards__K-eKI {
    display: flex;
    position: relative;
    min-height: 20em;
  }
  /* .NoCard {
    background-color: #f3f2f08a;
    border-radius: 14px;
    margin-left: 3em;
    margin-bottom: 2em;
    position: absolute;
    height: 30em;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    width: 20em;
  } */
}

/* large tablets & laptops */
@media screen and (min-width: 1200px) {
  .Travaux_SubHeader__2sM2i {
    padding: 1rem 11rem;
  }
  .Travaux_Travaux__3mQTr {
    margin: 0 11em;
    /* margin-bottom: 4em; */
  }
}

.Toolbar_Header__N4gef {
  background-color: #3b4578;
  padding: 2rem 1.5rem;
}

.Toolbar_Toolbar__3Et81 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Toolbar_Title__3sme7 {
  margin-top: 1.5rem;
  color: white;
  font-family: "Nunito Sans";
  line-height: 1.7rem;
}
.Toolbar_Title__3sme7 h3 {
  font-size: 1.8rem;
  text-transform: uppercase;
  font-weight: 900;
}
.Toolbar_Title__3sme7 h4 {
  font-size: 1.6rem;
  font-weight: 600;
}

.Toolbar_Description__2aLCr {
  color: white;
  margin-top: 2rem;
  font-weight: 400;
  font-size: 1.125rem;
}

/* large tablets & laptops */
@media screen and (min-width: 768px) {
  .Toolbar_Header__N4gef {
    padding: 4rem 4rem;
  }
  .Toolbar_Title__3sme7 h3 {
    font-size: 1.8rem;
  }
  .Toolbar_Title__3sme7 {
    display: flex;
  }
  .Toolbar_Title__3sme7 h4 {
    margin-left: 1rem;
  }
  .Toolbar_Description__2aLCr {
    width: 23rem;
  }
}

/* large tablets & laptops */
@media screen and (min-width: 1200px) {
  .Toolbar_Header__N4gef {
    padding: 2rem 11rem;
  }

  .Toolbar_Title__3sme7 h3 {
    font-size: 2rem;
  }
}

.Filter_Filter__QXCtp {
}
.Filter_Filter__QXCtp p {
  font-size: 0.9rem;
}
.Filter_Filter__QXCtp span {
  font-size: 1.5rem;
  font-weight: bold;
}

.Filter_Categories__3kh3u form {
  display: flex;
  justify-content: center;
  align-self: center;
  padding: 1rem 0;
  /* flex-wrap: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none; 
  scrollbar-width: none;  */
}
/* .Categories form::-webkit-scrollbar {
  display: none; 
} */

.Filter_Personnalisable__21hvh {
  margin: 0 0.5em;
  padding: 0.8em 0;
  flex: 0 0 auto;
}

.Filter_Personnalisable__21hvh label {
  background: #bcbecc;
  color: #5b5855;
  font-size: 0.85rem;
  border-radius: 24px;
  padding: 10px 15px;
  cursor: pointer;
}

.Filter_Personnalisable__21hvh input[type="checkbox"] {
  display: none;
}

.Filter_Personnalisable__21hvh input[type="checkbox"]:checked + label {
  background: white;
  color: #5b5855;
}

/* =========================== */
.Filter_Informative__3DAQE {
  margin: 0 0.5em;
  padding: 0.8em 0;
  flex: 0 0 auto;
}

.Filter_Informative__3DAQE label {
  background: #bcbecc;
  color: #5b5855;
  font-size: 0.85rem;
  border-radius: 24px;
  padding: 10px 15px;
  cursor: pointer;
}

.Filter_Informative__3DAQE input[type="checkbox"] {
  display: none;
}

.Filter_Informative__3DAQE input[type="checkbox"]:checked + label {
  background: white;
  color: #5b5855;
}

/* .CardList {
  margin-bottom: 2em;
}

.List {
  display: flex;
  flex-direction: column;
  align-items: center;
} */

/* large tablets & laptops */
/* @media screen and (min-width: 768px) {
  .List {
    align-items: flex-start;
    width: 21em;
  }
} */

.CardList_CardList__2nYRk {
  margin-bottom: 2em;
}

.CardList_List__3N1-l {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  padding: 0.5rem;
}

/* large tablets & laptops */
@media screen and (min-width: 768px) {
  /* .List {
    align-items: flex-start;
    width: 21em;
  } */
}

.Card_Card__2V3U3 {
  background-color: white;
  display: flex;
  border-radius: 14px;
  position: relative;
  max-width: 22em;
  margin: 0.5rem;
  margin-bottom: 1.5rem;
  cursor: pointer;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.1);
}
.Card_SelectedCard__LHF-u {
  border: 2px solid #e57373;
}

.Card_Thumbnail__24nK_ {
  width: 7em;
  height: 7em;
  flex: 0 0 7em;
  background-color: white;
  margin: 0.5em;
  border-radius: 14px;
  display: flex;
}
.Card_Thumbnail__24nK_ img {
  border-radius: 14px;
  width: 100%;
}

.Card_Main__3ZWf2 {
  margin: 0.5em 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.Card_Titre__2mUpF {
  font-size: 1rem;
  padding-right: 0.5rem;
}
.Card_Titre__2mUpF span {
  font-weight: 700;
}

.Card_Description__3pW2a {
  margin-right: 4em;
  font-size: 0.6rem;
}

.Card_Icon__B8nnC {
  background-color: #f3f2f0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0.4rem;
  border-radius: 0 14px 14px 0;
}

.Card_TagsContainer__2EuHA {
  display: flex;
}
.Card_TagContainer__2vvMh {
  font-size: 0.6rem;
  color: white;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-right: 1rem;
}
.Card_Tag__yDBhV {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.25rem 0.5rem;
  border-radius: 40px;
}
.Card_Tag__yDBhV p {
  padding: 0 0.2rem;
}

.Card_Notification__e8sh2 {
  position: absolute;
  top: -0.8rem;
  left: 1rem;
  width: 1.6rem;
  height: 1.6rem;
  border-radius: 1.6rem;
  background-color: #38bb6e;
  display: flex;
  justify-content: center;
  align-items: center;
}

.CardCreateReclamation_Card__73EMR {
  background-color: white;
  display: flex;
  border-radius: 14px;
  position: relative;
  max-width: 22em;
  margin: 0.5rem;
  margin-bottom: 1.5rem;
  cursor: pointer;

  border: 3px solid #3b4578;
  box-sizing: border-box;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.1);
}
.CardCreateReclamation_SelectedCard__1kv_p {
  border: 2px solid #e57373;
}

.CardCreateReclamation_Thumbnail__3LZYh {
  width: 7em;
  height: 7em;
  flex: 0 0 7em;
  background-color: white;
  margin: 0.5em;
  border-radius: 14px;
  align-self: center;
}
.CardCreateReclamation_Thumbnail__3LZYh img {
  border-radius: 14px;
  width: 100%;
}

.CardCreateReclamation_Main__1WwHL {
  margin: 0.5em 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.CardCreateReclamation_Titre__13pv9 {
  font-size: 1rem;
  padding-right: 0.5rem;
}
.CardCreateReclamation_Titre__13pv9 span {
  font-weight: 700;
}

.CardCreateReclamation_Description__4SoTe {
  margin-right: 4em;
  font-size: 0.6rem;
}

.CardCreateReclamation_Icon__1YuPW {
  background-color: #f3f2f0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0.4rem;
  border-radius: 0 14px 14px 0;
}

.CardCreateReclamation_TagContainer__1F2Ea {
  font-size: 0.6rem;
  color: white;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.CardCreateReclamation_Tag__2hH_X {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.25rem 0.5rem;
  border-radius: 40px;
}
.CardCreateReclamation_Tag__2hH_X p {
  padding: 0 0.2rem;
}

.ModalCreateReclamation_ModalCreateReclamation__2H61q {
  padding: 0;
  border-radius: 10px;
  padding: 1rem;
  outline: none;
  background-color: white;
  outline: none;
  overflow: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.ModalCreateReclamation_ModalCreateReclamation__2H61q::-webkit-scrollbar {
  display: none;
}

.ModalCreateReclamation_ModalContent__p8DKb {
  width: 100%;
  height: 100%;
  outline: none;
  background-color: #f3f2f0;
  padding: 2rem;
}

.ModalCreateReclamation_ModalTitle__2P2XN {
  margin-top: 1.4rem;
  margin-bottom: 1.7rem;
  font-size: 1.5rem;
  font-weight: 700;
  color: #3b4578;
}

/* large tablets & laptops */
@media screen and (min-width: 768px) {
  .ModalCreateReclamation_ModalContent__p8DKb {
    position: relative;
    border-radius: 10px;
    width: 30rem;
    height: 90%;
    max-height: 50rem;
    outline: none;
  }
}

/* large tablets & laptops */
@media screen and (min-width: 1200px) {
}

/* Very large screens */
@media screen and (min-width: 1500px) {
}

.ReclamationContent_IntroImage__UvXu4 {
  margin: auto;
  margin-top: 4rem;
}

.ReclamationContent_Buttons__4sInW {
  position: absolute;
  bottom: 2rem;
  right: 2rem;
  left: 2rem;
  display: flex;
  justify-content: space-between;
}

.ReclamationContent_QuestionTitle__1XQfN {
  margin-top: 0.5em;
  display: flex;
  /* justify-content: center; */
  align-items: center;
}
.ReclamationContent_QuestionTitle__1XQfN p {
  color: #2a2f39;
  margin-left: 0.4em;
}

.ReclamationContent_RadioFormTwo__tB9-K {
  display: flex;
  flex-wrap: wrap;
  margin-top: 1rem;
  justify-content: space-between;
}

.ReclamationContent_RadioElementTwo__lR2VS {
  margin-top: 1rem;
  /*flex: 1 0 32%; */
}

.ReclamationContent_RadioElementTwo__lR2VS input[type="radio"] {
  display: none;
}

.ReclamationContent_RadioElementTwo__lR2VS input[type="radio"] + label + p {
  text-align: left;
  font-size: 0.8rem;
  width: 5rem;
}

.ReclamationContent_RadioElementTwo__lR2VS input[type="radio"] + label {
  background-position: center center;
  background-size: contain;
  height: 5rem;
  width: 5rem;
  display: inline-block;
  position: relative;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.4);
  border-radius: 5px;
}

.ReclamationContent_RadioElementTwo__lR2VS input[type="radio"] + label:hover {
  cursor: pointer;
}

.ReclamationContent_RadioElementTwo__lR2VS input[type="radio"]:checked + label {
  border-color: #e0004d;
}

.ReclamationContent_RadioElementTwo__lR2VS input[type="radio"] + label i {
  font-size: 12px;
  position: absolute;
  left: 9.5em;
  bottom: -8px;
  color: #fff;
  background-color: #e0004d;
  border-radius: 100%;
  border: 2px solid #e0004d;
  padding: 2px;
  display: none;
}

.ReclamationContent_RadioElementTwo__lR2VS input[type="radio"]:checked + label i {
  display: inline-block;
}
.ReclamationContent_RadioElementTwo__lR2VS input[type="radio"]:checked + label + p {
  font-weight: bold;
}

.ReclamationContent_TitleField__3PEf2 {
  margin-top: 1rem;
}
.ReclamationContent_DescriptionField__y5XgM {
  margin-top: 1rem;
}

.ReclamationContent_ImagesRow__zZ7yM {
  margin-top: 0.5rem;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}
.ReclamationContent_ImagePlaceholder__2kBkc {
  background: #ffffff;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.4);
  border-radius: 5px;
  height: 5rem;
  width: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ReclamationContent_ImageUploaded__3D9CG {
  border-radius: 5px;
  height: 5rem;
  width: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.ReclamationContent_RecapTags__L30fi {
  display: flex;
}
.ReclamationContent_RecapTags__L30fi p {
  padding: 0.5rem;
  background-color: #ee9834;
  border-radius: 16px;
  margin-right: 1rem;
  color: white;
  font-size: 0.8rem;
}
.ReclamationContent_RecapTitle__15YBH {
  margin-top: 1rem;
}
.ReclamationContent_TitleArea__opE_l {
  margin-top: 0.5rem;
  padding: 0.5rem;
  background-color: white;
  height: 2.5rem;
  border-radius: 8px;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.ReclamationContent_TitleArea__opE_l::-webkit-scrollbar {
  display: none;
}
.ReclamationContent_RecapDescription__1Xw3f {
  margin-top: 1rem;
}
.ReclamationContent_DescriptionArea__12YwL {
  margin-top: 0.5rem;
  padding: 0.5rem;
  background-color: white;
  height: 7rem;
  border-radius: 8px;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.ReclamationContent_DescriptionArea__12YwL::-webkit-scrollbar {
  display: none;
}
.ReclamationContent_RecapImagesRow__2OePo {
  margin-top: 1rem;
  display: flex;
  justify-content: space-evenly;
}

/* large tablets & laptops */
@media screen and (min-width: 401px) {
  .ReclamationContent_RadioElementTwo__lR2VS {
    margin-top: 1rem;
    padding-left: 2rem;
    flex: 1 0 32%;
  }
}

.CardUserReclamation_Card__BD-Xu {
  background-color: white;
  display: flex;
  border-radius: 14px;
  position: relative;
  max-width: 22em;
  margin: 0.5rem;
  margin-bottom: 1.5rem;
  cursor: pointer;
  border: 3px solid #3b4578;
  box-sizing: border-box;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 14px;
}
.CardUserReclamation_SelectedCard__228Mn {
  border: 2px solid #e57373;
}

.CardUserReclamation_Thumbnail__2Ji5l {
  width: 7em;
  height: 7em;
  flex: 0 0 7em;
  background-color: white;
  margin: 0.5em;
  border-radius: 14px;
  align-self: center;
}
.CardUserReclamation_Thumbnail__2Ji5l img {
  border-radius: 14px;
  width: 100%;
}

.CardUserReclamation_Main__1wKJP {
  margin: 0.5em 0;
  min-width: 11rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.CardUserReclamation_Titre__3PFca {
  font-size: 1rem;
  padding-right: 0.5rem;
}
.CardUserReclamation_Titre__3PFca span {
  font-weight: 700;
}

.CardUserReclamation_Description__31iOo {
  margin-right: 4em;
  font-size: 0.6rem;
}

.CardUserReclamation_Icon__3bILv {
  background-color: #f3f2f0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0.4rem;
  border-radius: 0 14px 14px 0;
}

.CardUserReclamation_TagContainer__1B2z9 {
  font-size: 0.6rem;
  color: white;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.CardUserReclamation_Tag__2Hj1K {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.25rem 0.5rem;
  border-radius: 40px;
}
.CardUserReclamation_Tag__2Hj1K p {
  padding: 0 0.2rem;
}

.CardUserReclamation_Notification__1md-t {
  position: absolute;
  top: -1.2rem;
  left: 1rem;
  padding: 0.3rem;
  border-radius: 100px;
  background-color: #fcb34a;
}

.ModalUserReclamation_ModalCreateReclamation__Lmwmp {
  padding: 0;
  border-radius: 10px;
  padding: 1rem;
  outline: none;
  background-color: white;
  outline: none;
  overflow: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.ModalUserReclamation_ModalCreateReclamation__Lmwmp::-webkit-scrollbar {
  display: none;
}

.ModalUserReclamation_ModalContent__1aMbi {
  width: 100%;
  height: 100%;
  outline: none;
  background-color: #f3f2f0;
  padding: 2rem;
}

.ModalUserReclamation_ModalTitle__2_hRb {
  margin-top: 1.4rem;
  margin-bottom: 1.7rem;
  font-size: 1.5rem;
  font-weight: 700;
  color: #3b4578;
}

/* large tablets & laptops */
@media screen and (min-width: 768px) {
  .ModalUserReclamation_ModalContent__1aMbi {
    position: relative;
    border-radius: 10px;
    width: 30rem;
    height: 90%;
    max-height: 50rem;
    outline: none;
  }
}

/* large tablets & laptops */
@media screen and (min-width: 1200px) {
}

/* Very large screens */
@media screen and (min-width: 1500px) {
}

.ReclamationContent_IntroImage__2UYG7 {
  margin: auto;
  margin-top: 4rem;
}

.ReclamationContent_Buttons__32uzP {
  position: absolute;
  bottom: 2rem;
  right: 2rem;
  left: 2rem;
  display: flex;
  justify-content: space-between;
}

.ReclamationContent_QuestionTitle__2CXgr {
  margin-top: 0.5em;
  display: flex;
  /* justify-content: center; */
  align-items: center;
}
.ReclamationContent_QuestionTitle__2CXgr p {
  color: #2a2f39;
  margin-left: 0.4em;
}

.ReclamationContent_RadioFormTwo__3xgTz {
  display: flex;
  flex-wrap: wrap;
  margin-top: 1rem;
  justify-content: space-between;
}

.ReclamationContent_RadioElementTwo__3xZVd {
  margin-top: 1rem;
  /*flex: 1 0 32%; */
}

.ReclamationContent_RadioElementTwo__3xZVd input[type="radio"] {
  display: none;
}

.ReclamationContent_RadioElementTwo__3xZVd input[type="radio"] + label + p {
  text-align: left;
  font-size: 0.8rem;
  width: 5rem;
}

.ReclamationContent_RadioElementTwo__3xZVd input[type="radio"] + label {
  background-position: center center;
  background-size: contain;
  height: 5rem;
  width: 5rem;
  display: inline-block;
  position: relative;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.4);
  border-radius: 5px;
}

.ReclamationContent_RadioElementTwo__3xZVd input[type="radio"] + label:hover {
  cursor: pointer;
}

.ReclamationContent_RadioElementTwo__3xZVd input[type="radio"]:checked + label {
  border-color: #e0004d;
}

.ReclamationContent_RadioElementTwo__3xZVd input[type="radio"] + label i {
  font-size: 12px;
  position: absolute;
  left: 9.5em;
  bottom: -8px;
  color: #fff;
  background-color: #e0004d;
  border-radius: 100%;
  border: 2px solid #e0004d;
  padding: 2px;
  display: none;
}

.ReclamationContent_RadioElementTwo__3xZVd input[type="radio"]:checked + label i {
  display: inline-block;
}
.ReclamationContent_RadioElementTwo__3xZVd input[type="radio"]:checked + label + p {
  font-weight: bold;
}

.ReclamationContent_TitleField__3263q {
  margin-top: 1rem;
}
.ReclamationContent_DescriptionField__29z4T {
  margin-top: 1rem;
}

.ReclamationContent_ImagesRow__g_QH6 {
  margin-top: 0.5rem;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}
.ReclamationContent_ImagePlaceholder__29vjz {
  background: #ffffff;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.4);
  border-radius: 5px;
  height: 5rem;
  width: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ReclamationContent_ImageUploaded__3yR_y {
  border-radius: 5px;
  height: 5rem;
  width: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.ReclamationContent_RecapTags__23STS {
  display: flex;
}
.ReclamationContent_RecapTags__23STS p {
  padding: 0.5rem;
  background-color: #ee9834;
  border-radius: 16px;
  margin-right: 1rem;
  color: white;
  font-size: 0.8rem;
}
.ReclamationContent_RecapTitle__O04Kx {
  margin-top: 1rem;
}
.ReclamationContent_TitleArea__3ukwf {
  margin-top: 0.5rem;
  padding: 0.5rem;
  background-color: white;
  height: 2.5rem;
  border-radius: 8px;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.ReclamationContent_TitleArea__3ukwf::-webkit-scrollbar {
  display: none;
}
.ReclamationContent_RecapDescription__CgYYW {
  margin-top: 1rem;
}
.ReclamationContent_DescriptionArea__2ULKz {
  margin-top: 0.5rem;
  padding: 0.5rem;
  background-color: white;
  height: 7rem;
  border-radius: 8px;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.ReclamationContent_DescriptionArea__2ULKz::-webkit-scrollbar {
  display: none;
}
.ReclamationContent_RecapImagesRow__1lIZC {
  margin-top: 1rem;
  display: flex;
  justify-content: space-evenly;
}

/* large tablets & laptops */
@media screen and (min-width: 401px) {
  .ReclamationContent_RadioElementTwo__3xZVd {
    margin-top: 1rem;
    padding-left: 2rem;
    flex: 1 0 32%;
  }
}

.CardAdminReclamation_Card__2i8a3 {
  background-color: white;
  display: flex;
  border-radius: 14px;
  position: relative;
  max-width: 22em;
  margin: 0.5rem;
  margin-bottom: 1.5rem;
  cursor: pointer;
  border: 3px solid #3b4578;
  box-sizing: border-box;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 14px;
}
.CardAdminReclamation_SelectedCard__2_P2Q {
  border: 2px solid #e57373;
}

.CardAdminReclamation_Thumbnail__2kHDZ {
  width: 7em;
  height: 7em;
  flex: 0 0 7em;
  background-color: white;
  margin: 0.5em;
  border-radius: 14px;
  align-self: center;
}
.CardAdminReclamation_Thumbnail__2kHDZ img {
  border-radius: 14px;
  width: 100%;
}

.CardAdminReclamation_Main__10jGR {
  margin: 0.5em 0;
  min-width: 11rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.CardAdminReclamation_Titre__3M5GJ {
  font-size: 1rem;
  padding-right: 0.5rem;
}
.CardAdminReclamation_Titre__3M5GJ span {
  font-weight: 700;
}

.CardAdminReclamation_Description__1HUyN {
  margin-right: 4em;
  font-size: 0.6rem;
}

.CardAdminReclamation_Icon__1Uv2m {
  background-color: #f3f2f0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0.4rem;
  border-radius: 0 14px 14px 0;
}

.CardAdminReclamation_TagContainer__19zFX {
  font-size: 0.6rem;
  color: white;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.CardAdminReclamation_Tag__E2y3K {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.25rem 0.5rem;
  border-radius: 40px;
}
.CardAdminReclamation_Tag__E2y3K p {
  padding: 0 0.2rem;
}

.CardAdminReclamation_Notification__1IY78 {
  position: absolute;
  top: -1.2rem;
  left: 1rem;
  padding: 0.3rem;
  border-radius: 100px;
  background-color: #fcb34a;
}

.ModalUserReclamation_ModalCreateReclamation__kwRYS {
  padding: 0;
  border-radius: 10px;
  padding: 1rem;
  outline: none;
  background-color: white;
  outline: none;
  overflow: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.ModalUserReclamation_ModalCreateReclamation__kwRYS::-webkit-scrollbar {
  display: none;
}

.ModalUserReclamation_ModalContent__1iu5Y {
  width: 100%;
  height: 100%;
  outline: none;
  background-color: #f3f2f0;
  padding: 2rem;
}

.ModalUserReclamation_ModalTitle__MWt-U {
  margin-top: -0.5rem;
  margin-bottom: 1.7rem;
  font-size: 0.9rem;
  font-weight: 700;
  color: #3b4578;
  display: flex;
  justify-content: space-between;
}

/* large tablets & laptops */
@media screen and (min-width: 768px) {
  .ModalUserReclamation_ModalContent__1iu5Y {
    position: relative;
    border-radius: 10px;
    width: 30rem;
    height: 90%;
    max-height: 50rem;
    outline: none;
  }
}

/* large tablets & laptops */
@media screen and (min-width: 1200px) {
}

/* Very large screens */
@media screen and (min-width: 1500px) {
}

.ReclamationContent_IntroImage__MsUA- {
  margin: auto;
  margin-top: 4rem;
}

.ReclamationContent_Buttons__3bpi8 {
  bottom: 2rem;
  right: 2rem;
  left: 2rem;
  display: flex;
  justify-content: space-between;
}

.ReclamationContent_QuestionTitle__yJDXj {
  margin-top: 0.5em;
  display: flex;
  /* justify-content: center; */
  align-items: center;
}
.ReclamationContent_QuestionTitle__yJDXj p {
  color: #2a2f39;
  margin-left: 0.4em;
}

.ReclamationContent_RadioFormTwo__33NgJ {
  display: flex;
  flex-wrap: wrap;
  margin-top: 1rem;
  justify-content: space-between;
}

.ReclamationContent_RadioElementTwo__2_l3v {
  margin-top: 1rem;
  /*flex: 1 0 32%; */
}

.ReclamationContent_RadioElementTwo__2_l3v input[type="radio"] {
  display: none;
}

.ReclamationContent_RadioElementTwo__2_l3v input[type="radio"] + label + p {
  text-align: left;
  font-size: 0.8rem;
  width: 5rem;
}

.ReclamationContent_RadioElementTwo__2_l3v input[type="radio"] + label {
  background-position: center center;
  background-size: contain;
  height: 5rem;
  width: 5rem;
  display: inline-block;
  position: relative;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.4);
  border-radius: 5px;
}

.ReclamationContent_RadioElementTwo__2_l3v input[type="radio"] + label:hover {
  cursor: pointer;
}

.ReclamationContent_RadioElementTwo__2_l3v input[type="radio"]:checked + label {
  border-color: #e0004d;
}

.ReclamationContent_RadioElementTwo__2_l3v input[type="radio"] + label i {
  font-size: 12px;
  position: absolute;
  left: 9.5em;
  bottom: -8px;
  color: #fff;
  background-color: #e0004d;
  border-radius: 100%;
  border: 2px solid #e0004d;
  padding: 2px;
  display: none;
}

.ReclamationContent_RadioElementTwo__2_l3v input[type="radio"]:checked + label i {
  display: inline-block;
}
.ReclamationContent_RadioElementTwo__2_l3v input[type="radio"]:checked + label + p {
  font-weight: bold;
}

.ReclamationContent_TitleField__J1Oz8 {
  margin-top: 1rem;
}
.ReclamationContent_DescriptionField__21JFq {
  margin-top: 1rem;
}

.ReclamationContent_ImagesRow__1s5qW {
  margin-top: 0.5rem;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}
.ReclamationContent_ImagePlaceholder__qbFHA {
  background: #ffffff;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.4);
  border-radius: 5px;
  height: 5rem;
  width: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ReclamationContent_ImageUploaded__EYC0Z {
  border-radius: 5px;
  height: 5rem;
  width: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
}

.ReclamationContent_RecapTags__1zKgh {
  display: flex;
}
.ReclamationContent_RecapTags__1zKgh p {
  padding: 0.5rem;
  background-color: #ee9834;
  border-radius: 16px;
  margin-right: 1rem;
  color: white;
  font-size: 0.8rem;
}
.ReclamationContent_RecapTitle__1yPCS {
  margin-top: 1rem;
}
.ReclamationContent_TitleArea__3u7x1 {
  margin-top: 0.5rem;
  padding: 0.5rem;
  background-color: white;
  height: 2.5rem;
  border-radius: 8px;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.ReclamationContent_TitleArea__3u7x1::-webkit-scrollbar {
  display: none;
}
.ReclamationContent_RecapDescription__1zsp0 {
  margin-top: 1rem;
}
.ReclamationContent_DescriptionArea__2uWVG {
  margin-top: 0.5rem;
  padding: 0.5rem;
  background-color: white;
  height: 5rem;
  border-radius: 8px;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.ReclamationContent_DescriptionArea__2uWVG::-webkit-scrollbar {
  display: none;
}
.ReclamationContent_RecapImagesRow__24eTp {
  margin-top: 1rem;
  display: flex;
  justify-content: space-evenly;
}

/* large tablets & laptops */
@media screen and (min-width: 401px) {
  .ReclamationContent_RadioElementTwo__2_l3v {
    margin-top: 1rem;
    padding-left: 2rem;
    flex: 1 0 32%;
  }
}

.Footer_Footer__2aY-f {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.Footer_LogoContainer__1wOD4 {
  width: 15em;
  margin: 1em 0;
}
.Footer_LogoContainer__1wOD4 img {
  width: 100%;
}
.Footer_LegalLinks__2hN_V {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0.5rem;
}
.Footer_LegalLinks__2hN_V p {
  cursor: pointer;
  margin: 0.5rem;
  font-size: 0.8rem;
  color: #828282;
  padding: 0.8rem 1rem;
  background-color: #f3f2f0;
  border-radius: 10px;
}
.Footer_Copyrights__14mWm {
  margin: 0.5rem 0;
  color: #5b5855;
  text-align: center;
  font-size: 0.8rem;
}
.Footer_Copyrights__14mWm span {
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 900;
  font-family: "Nunito Sans", sans-serif;
}
.Footer_ImmobiliereLogo__2qryp {
  width: 12rem;
  margin-bottom: 1rem;
  margin-left: auto;
  margin-right: auto;
}

.PDC_CGUTitle__J1BwQ {
  text-align: center;
}

.PDC_CGUWrapper__2cBO- {
  margin-top: 20px;
  height: 50vh;
  overflow-y: scroll;
}

.PDC_Article__3U3kq {
  margin-bottom: 1em;
}

.PDC_ArticleTitle__137gL {
  margin-bottom: 0.5em;
}
.PDC_ArticleSubtitle__2_5To {
  margin-bottom: 0.5em;
}
.PDC_ArticleContent__2PhdC {
  margin-bottom: 0.5em;
}
.PDC_ArticleContent__2PhdC p {
  margin-bottom: 0.3em;
}

.CardModal_CardModal__252NP {
  position: fixed;
  z-index: 500;
  background: white;
  overflow-y: scroll;
  /* border-radius: 9px; */
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

/* large tablets & laptops */
@media screen and (min-width: 768px) {
  .CardModal_CardModal__252NP {
    position: static;
    overflow-y: hidden;
    background-color: #f3f2f0;
    width: auto;
    height: auto;
    padding: 2em;
    border-radius: 14px;
    margin-left: 3em;
    margin-bottom: 2em;
  }
}

/* large tablets & laptops */
@media screen and (min-width: 1400px) {
  .CardModal_CardModal__252NP {
    padding: 0;
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2), 0px 3px 14px rgba(0, 0, 0, 0.12),
      0px 8px 10px rgba(0, 0, 0, 0.14);
    border-radius: 3px;
  }
}

.NonConcertableLayoutOne_Card__3TJSV {
  background-color: white;
  outline: none;
  overflow: scroll;
}

.NonConcertableLayoutOne_CardHeader__2XLpl {
  display: flex;
  top: 0;
  left: 0;
  margin: 2rem 2.5rem;
  align-items: center;
  justify-content: space-between;
  /* background-color: white; */
  height: 3em;
  margin-bottom: 1.5em;
}

.NonConcertableLayoutOne_CardTitle__IElCD h3 {
  margin-top: 1em;
  font-size: 1.5rem;
  color: #292f33;
  font-weight: bold;
}
.NonConcertableLayoutOne_CardTitle__IElCD h4 {
  font-size: 1.3rem;
  color: #5b5855;
  font-weight: normal;
}

.NonConcertableLayoutOne_VideoPreview__1KfEm {
  /* margin: 0.5em auto;
  width: 80%;
  max-width: 20em; */
  margin: 1em auto;
  display: flex;
  justify-content: center;
}

.NonConcertableLayoutOne_Description__3H6Mg {
  margin: 0.5rem 0;
  color: #898786;
  font-size: 0.9rem;
}

.NonConcertableLayoutOne_DescriptionPlus__23YTS {
  background-color: #e0e0e0;
  border-radius: 5px;
  padding: 0.5rem 1rem;
  color: #898786;
  font-size: 0.9rem;
  margin-top: 1.5rem;
}
.NonConcertableLayoutOne_DescriptionPlus__23YTS span {
  color: #3d9dc7;
  font-weight: 900;
}

.NonConcertableLayoutOne_CardContent__7NTtO {
  margin-top: 2rem;
  padding: 2rem 2.5em;
  background-color: #f3f2f0;
}

.NonConcertableLayoutOne_Emoji__s2Eq- {
  font-size: 1.8rem;
}

.NonConcertableLayoutOne_QuestionsHeader__1sJNU {
  color: #3b4578;
  margin-top: 0.7em;
  margin-bottom: 1.5rem;
}

.NonConcertableLayoutOne_QuestionTitle__2mE82 {
  margin-top: 0.5em;
  font-size: 1rem;
  display: flex;
  /* justify-content: center; */
  align-items: center;
}
.NonConcertableLayoutOne_QuestionTitle__2mE82 p {
  color: #2a2f39;
  margin-left: 0.4em;
}

.NonConcertableLayoutOne_CommentSection__2-NX0 {
  margin-top: 2rem;
  text-align: center;
}

.NonConcertableLayoutOne_Buttons__2ilYQ {
  display: flex;
  margin: 2rem 0;
  justify-content: space-between;
}

.NonConcertableLayoutOne_NCSection__3iSDF {
  margin-top: 1.1em;
}
.NonConcertableLayoutOne_MapImage__2Vna- {
  margin-top: 0.85em;
  /* box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.12),
    0px 2px 4px rgba(0, 0, 0, 0.14); */
  /* border-radius: 10px; */
}

.NonConcertableLayoutOne_Notices__1hJRE {
  margin: 2em 0;
  font-size: 0.6rem;
}

/* Desktops */
@media screen and (min-width: 910px) {
  .NonConcertableLayoutOne_Card__3TJSV {
    padding: 0;
    border-radius: 10px;
    width: 80%;
    height: 90%;
    max-width: 50rem;
    max-height: 40rem;
    outline: none;
    display: flex;
  }
  .NonConcertableLayoutOne_Card__3TJSV::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .NonConcertableLayoutOne_Card__3TJSV {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .NonConcertableLayoutOne_VideoPreview__1KfEm {
    margin-top: 3rem;
  }

  .NonConcertableLayoutOne_LeftSection__3cs_F {
    background-color: white;
    width: 50%;
    padding: 1em;
    padding-bottom: 3em;
  }

  .NonConcertableLayoutOne_RightSection__1Ocbk {
    background-color: #f3f2f0;
    width: 50%;
    height: 100%;
    position: relative;
  }

  .NonConcertableLayoutOne_DescriptionPlus__23YTS {
    margin-top: 0;
  }

  .NonConcertableLayoutOne_CardContent__7NTtO {
    height: 100%;
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.Switch_switch__1-V6O {
  position: relative;
  display: inline-block;
  width: 220px;
  height: 60px;
}

.Switch_switch__1-V6O input {
  opacity: 0;
  width: 0;
  height: 0;
}

.Switch_slider__cGCH9 {
  position: absolute;
  cursor: pointer;

  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background: rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  box-shadow: 0px 0px 55px rgba(0, 0, 0, 0.1);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.Switch_slider__cGCH9:before {
  position: absolute;
  content: "";

  width: 110px;
  height: 59px;
  left: 0px;
  top: 0px;

  background: #e2e2e2;
  box-shadow: inset 0px -7px 0px #bebebe;
  border-radius: 20px;

  -webkit-transition: 0.4s;

  transition: 0.4s;
}

input:checked + .Switch_slider__cGCH9:before {
  background: #f5a623;
  box-shadow: inset 0px -7px 0px #d28e1e;
  border-radius: 20px;
  -webkit-transform: translateX(110px);
          transform: translateX(110px);
}

input:checked + .Switch_off__3Kviu {
  color: #ffffff;
}

.concertableLayoutOne_Card__BIVoq {
  background-color: white;
  outline: none;
  overflow: scroll;
}

.concertableLayoutOne_CardHeader__2uKOb {
  display: flex;
  top: 0;
  left: 0;
  margin: 2rem 2.5rem;
  align-items: center;
  justify-content: space-between;
  /* background-color: white; */
  height: 3em;
  margin-bottom: 1.5em;
}

.concertableLayoutOne_CardTitle__xtIJ3 h3 {
  margin-top: 1em;
  font-size: 1.5rem;
  color: #292f33;
  font-weight: bold;
}
.concertableLayoutOne_CardTitle__xtIJ3 h4 {
  font-size: 1.3rem;
  color: #5b5855;
  font-weight: normal;
}

.concertableLayoutOne_ImagePreview__2nnES {
  margin: 0.5em auto;
  width: 80%;
  max-width: 20em;
}
.concertableLayoutOne_ImagePreview__2nnES img {
  width: 100%;
}

.concertableLayoutOne_Description__3VjN0 {
  margin: 0.5rem 2.5rem;
  color: #898786;
  font-size: 0.9rem;
}

.concertableLayoutOne_CardContent__As7s8 {
  margin-top: 2rem;
  padding: 2rem 2.5em;
  background-color: #f3f2f0;
}

.concertableLayoutOne_Emoji__3SyKU {
  font-size: 1.8rem;
}

.concertableLayoutOne_QuestionsHeader__36yUJ {
  color: #3b4578;
  margin-top: 0.7em;
  margin-bottom: 1.5rem;
}

.concertableLayoutOne_QuestionTitle__36uWJ {
  margin-top: 0.5em;
  font-size: 1rem;
  display: flex;
  font-weight: 900;
  /* justify-content: center; */
  align-items: center;
}
.concertableLayoutOne_QuestionTitle__36uWJ p {
  color: #2a2f39;
  margin-left: 0.4em;
}

/* ============================== radio form */
.concertableLayoutOne_RadioForm__bDk3j {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
}

.concertableLayoutOne_RadioElement__rv-Yo {
  margin: 1em 0.8em;
}

.concertableLayoutOne_RadioElement__rv-Yo label {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.concertableLayoutOne_RadioElement__rv-Yo p {
  text-align: center;
  margin-top: 0.5em;
  font-size: 0.85em;
}

.concertableLayoutOne_RadioElement__rv-Yo img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 5em;
  /* border-radius: 50%; */
  /* box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2), 0px 3px 14px rgba(0, 0, 0, 0.12),
    0px 8px 10px rgba(0, 0, 0, 0.14); */
}

.concertableLayoutOne_RadioElement__rv-Yo input {
  display: none;
}

.concertableLayoutOne_RadioElement__rv-Yo input[type="radio"]:checked + label img {
  border: 3px solid #e31869;
}
.concertableLayoutOne_RadioElement__rv-Yo input[type="radio"]:checked + label p {
  font-weight: bold;
}

/* TESTING ====================================================================== */
/* .RadioForm .RadioElementTwo {
  margin: 10px 5px 5px 0;
  display: inline-block;
} */

.concertableLayoutOne_RadioFormTwo__1losd {
  margin: 1em 0;
  display: flex;
  flex-wrap: wrap;
  /* justify-content: left; */
}

.concertableLayoutOne_RadioElementTwo__2-LvA {
  margin: 1em 1em;
}

.concertableLayoutOne_RadioElementTwo__2-LvA input[type="radio"] {
  display: none;
}

.concertableLayoutOne_RadioElementTwo__2-LvA input[type="radio"] + label + p {
  text-align: center;
  width: 8em;
}

.concertableLayoutOne_RadioElementTwo__2-LvA input[type="radio"] + label {
  background-position: center center;
  background-size: contain;
  height: 8em;
  width: 8em;
  display: inline-block;
  position: relative;
  border: 4px solid rgb(196, 196, 196);
  border-radius: 5%;
}

.concertableLayoutOne_RadioElementTwo__2-LvA input[type="radio"] + label:hover {
  cursor: pointer;
}

.concertableLayoutOne_RadioElementTwo__2-LvA input[type="radio"]:checked + label {
  border-color: #e0004d;
}

.concertableLayoutOne_RadioElementTwo__2-LvA input[type="radio"] + label i {
  font-size: 12px;
  position: absolute;
  left: 9.5em;
  bottom: -8px;
  color: #fff;
  background-color: #e0004d;
  border-radius: 100%;
  border: 2px solid #e0004d;
  padding: 2px;
  display: none;
}

.concertableLayoutOne_RadioElementTwo__2-LvA input[type="radio"]:checked + label i {
  display: inline-block;
}
.concertableLayoutOne_RadioElementTwo__2-LvA input[type="radio"]:checked + label + p {
  font-weight: bold;
}

/* ============ END RADIO FORM */

.concertableLayoutOne_CommentSection__1bRjD {
  margin-top: 2rem;
  text-align: center;
}

.concertableLayoutOne_Buttons__3xHbv {
  display: flex;
  margin: 2rem 0;
  justify-content: space-between;
}

.concertableLayoutOne_MUIForm__19R-9 {
  margin-left: 2em;
}

.concertableLayoutOne_NCSection__3hxxR {
  margin-top: 1.1em;
}
.concertableLayoutOne_MapImage__2yKFU {
  margin-top: 0.85em;
  /* box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.12),
    0px 2px 4px rgba(0, 0, 0, 0.14); */
  /* border-radius: 10px; */
}

.concertableLayoutOne_Notices__IVIn9 {
  margin: 2em 0;
  font-size: 0.6rem;
}

/* Desktops */
@media screen and (min-width: 910px) {
  .concertableLayoutOne_Card__BIVoq {
    padding: 0;
    border-radius: 10px;
    width: 80%;
    height: 90%;
    outline: none;
    display: flex;
  }
  .concertableLayoutOne_Card__BIVoq::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .concertableLayoutOne_Card__BIVoq {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .concertableLayoutOne_Description__3VjN0 {
  }

  .concertableLayoutOne_LeftSection__2oJjo {
    background-color: white;
    width: 50%;
    padding: 1em;
    padding-bottom: 3em;
  }

  .concertableLayoutOne_RightSection__3h03Q {
    background-color: #f3f2f0;
    width: 50%;
    height: 100%;
    position: relative;
  }

  .concertableLayoutOne_CardContent__As7s8 {
    height: 100%;
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.ProfilePage_HeaderImageWrapper__1WSNP {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
}
.ProfilePage_HeaderImageWrapper__1WSNP img {
  width: 80%;
}

.ProfilePage_ProfilePage__19tsN h1 {
  margin: 1rem 2rem;
}
.ProfilePage_ProfilePage__19tsN h3 {
  margin: 1rem 0 1rem 2rem;
}
.ProfilePage_SubmitButton__1LLgq {
  height: 6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 2rem;
}

.OvalButton_OvalButton__1WVM7 {
  display: flex;
  margin: 0 auto;
  padding: 20px 40px;
  border: 0;
  outline: 0;
  background: #ebecf0;
  border: 1px solid rgba(255, 255, 255, 0.7);
  box-sizing: border-box;
  box-shadow: 5px 5px 10px #a6abbd, -5px -5px 10px #fafbff;
  border-radius: 41px;
}

.OvalButton_OvalButton__1WVM7:active {
  box-shadow: inset 1px 1px 2px #babecc, inset -1px -1px 2px #fff;
}

.Resultats_Main__2-Pyi {
  padding: 0 1em;
  margin-bottom: 2em;
}

.Resultats_Title__2NRUY {
  font-size: 0.9rem;
  margin-bottom: 2em;
}
.Resultats_Title__2NRUY span {
  font-size: 1.5rem;
  font-weight: bold;
}
.Resultats_StatsIllustration__2VFoJ {
  margin-top: 2em;
}
.Resultats_StatsContent__3ayNo {
  display: flex;
  flex-direction: column;
  text-align: center;
}
.Resultats_StatsContent__3ayNo h3 {
  font-size: 1.4rem;
  font-weight: normal;
}
.Resultats_StatsContent__3ayNo p {
  font-size: 2rem;
  font-weight: bold;
}
.Resultats_Illustration__1LPN8 {
  margin: 1em auto;
  width: 80%;
  max-width: 25em;
  display: flex;
  justify-content: center;
}

/* large tablets & laptops */
@media screen and (min-width: 768px) {
  .Resultats_Resultats__2kUvr {
    margin: 0 2em;
    margin-top: 1em;
  }
}
/* large tablets & laptops */
@media screen and (min-width: 1200px) {
  .Resultats_Resultats__2kUvr {
    margin: 0 10em;
  }
}

.PageOne_SectionTitle__3opfQ {
  text-align: center;
}
.PageOne_SectionTitle__3opfQ .PageOne_Emoji__39GOm {
  font-size: 2.8em;
}
.PageOne_SectionTitle__3opfQ h3 {
  font-size: 1.5em;
}
.PageOne_SectionSubtitle__3kwOz {
  font-weight: bold;
  font-size: 0.8em;
  margin: 0.8em 2em;
}

.PageOne_EmojiText__tvIsS {
  display: flex;
  margin: 0 2em;
}
.PageOne_EmojiText__tvIsS .PageOne_Emoji__39GOm {
  font-size: 1.2em;
}

.PageOne_EmojiText__tvIsS p {
  font-size: 0.9em;
  margin-left: 0.5em;
  margin-top: 0.3em;
}

.PageOne_ParticiperButton__3BcNy {
  text-align: center;
  margin: 1.5em 0;
}

.PageOne_Illustration__OcMnC {
  width: 275px;
  height: 275px;
  box-shadow: 2px 2px 6px #a6abbd, -2px -2px 6px #fafbff;
  border-radius: 8px;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 2em auto;
}

/* Caroussel */
.PageOne_CarouselSection__3y9Ij {
  margin: 30px 0;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.PageOne_CarouselSection__3y9Ij::-webkit-scrollbar {
  display: none; /* Safari & Chrome*/
}

.PageOne_CarouselItem__38B8Z {
  flex: 0 0 auto;
  margin-bottom: 5px;
  margin-top: 5px;
  margin-right: 30px;
  width: 275px;
  height: 275px;
  box-shadow: 2px 2px 6px #a6abbd, -2px -2px 6px #fafbff;
  border-radius: 8px;
  background-repeat: no-repeat;
  background-size: cover;
}
.PageOne_CarouselItem__38B8Z:first-child {
  margin-left: 40px;
}
.PageOne_CourselItem__2ydcc:last-child {
  margin-right: 20px;
}

.PageOne_GrandesEtapes__pOO_i {
  display: flex;
  justify-content: center;
}
.PageOne_GrandesEtapes__pOO_i img {
  width: 80%;
  max-width: 33em;
  margin-bottom: -3em;
}
.PageOne_HeroImage__1q_xX {
  width: 100%;
  margin-bottom: 1.5em;
  z-index: -1;
}
.PageOne_HeroImage__1q_xX img {
  width: 100%;
}

/* large tablets & laptops */
@media screen and (min-width: 768px) {
  .PageOne_HeroImage__1q_xX {
    width: 40em;
    margin: 0 auto;
    margin-bottom: 3em;
  }
  .PageOne_HeroImage__1q_xX img {
    border-radius: 8px;
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.12),
      0px 2px 4px rgba(0, 0, 0, 0.14);
  }
  .PageOne_Section__Y92Rz {
    margin: 0 5.5em;
  }
  .PageOne_SectionTitle__3opfQ {
    display: flex;
    align-items: flex-end;
  }
  .PageOne_SectionSubtitle__3kwOz {
    font-weight: bold;
    font-size: 0.9em;
    margin: 0.8em 0;
  }
  .PageOne_ContactForm__Vzsfv {
    margin: 0 auto;
    max-width: 40em;
  }
}

/* large tablets & laptops */
@media screen and (min-width: 1200px) {
  .PageOne_HeroImage__1q_xX {
    width: 50em;
    margin: 0 auto;
    margin-bottom: 4em;
  }
  .PageOne_Section__Y92Rz {
    margin: 0 20em;
  }
  .PageOne_Illustration__OcMnC {
    width: 25em;
    height: 25em;
  }
}

.ContactForm_ContactForm__SHLIz {
  margin: 1.5em 0.3em;
}
.ContactForm_ContactFormTitle__3KcJh {
  font-weight: bold;
  font-size: 1rem;
  text-align: center;
}
.ContactForm_Emoji__2P9Dh {
  font-size: 1.5rem;
}
.ContactForm_ContactFormFields__2sz5q {
  margin: 2em;
  display: flex;
  flex-direction: column;
}
.ContactForm_Button__3YocY {
  text-align: end;
}
.ContactForm_Success__c7cm6 {
  margin-left: 0.2em;
  margin-bottom: 1em;
  color: green;
  font-size: 0.8rem;
}
.ContactForm_Error__1ZNVY {
  margin-bottom: 1em;
  margin-left: 0.2em;
  color: red;
  font-size: 0.8rem;
}

.Separator_Separator__1Ny2f {
  margin: 0 auto;
  height: 4px;
  width: 90%;
  max-width: 60em;
  background: #ebecf0;
  border: 1px solid rgba(255, 255, 255, 0.4);
  box-sizing: border-box;
  box-shadow: inset 1px 3px 5px #a6abbd, inset 7px 4px 14px #fafbff;
  border-radius: 4px;
}

/* .Content {
  margin-top: 56px;
} */

/* Small tablets */

/* Large tablets & laptops */
@media (min-width: 960px) {
  .Layout_Content__3_Rgc {
    margin-top: 0px;
  }
}

.Toolbar_Toolbar__3orfh {
  height: 57px;
  width: 100%;
  top: 0;
  left: 0;
  position: fixed;
  background-color: #ebecf0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  box-sizing: border-box;
  z-index: 90;
  box-shadow: 5px 5px 10px #a6abbd, -5px -5px 10px #fafbff;
}

/* .Logo {
  height: 60%;
} */
.Toolbar_Logo__AAisl h3 {
  text-transform: uppercase;
  font-size: 1.8rem;
  color: #5b5855;
  font-weight: bold;
  cursor: pointer;
}

@media (max-width: 619px) {
  .Toolbar_DesktopOnly__17Dc1 {
    display: none;
  }
}

/* Small tablets */
@media (min-width: 620px) {
  .Toolbar_Toolbar__3orfh {
    /* box-shadow: none; */
    height: 70px;
    padding: 0 40px;
    /* position: relative; */
    background-color: white;
  }
  .Toolbar_Logo__AAisl h3 {
    font-size: 1.8rem;
  }
}

/* Large Tablets & laptops */
@media screen and (min-width: 960px) {
  .Toolbar_Toolbar__3orfh {
    /* height: 100px; */
    padding: 10px 80px;
  }
  .Toolbar_Logo__AAisl h3 {
    font-size: 2rem;
  }
}

/* Large Tablets & laptops */
@media screen and (min-width: 1200px) {
  .Toolbar_Toolbar__3orfh {
    padding: 10px 100px;
  }
}

.SideDrawer_SideDrawer__17lCf {
  position: fixed;
  width: 280px;
  max-width: 60%;
  height: 100%;
  right: 0;
  top: 0;
  z-index: 200;
  background-color: #ebecf0;
  padding: 32px 16px;
  box-sizing: border-box;
  -webkit-transition: -webkit-transform 0.3s ease-out;
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
}

@media (min-width: 620px) {
  .SideDrawer_SideDrawer__17lCf {
    display: none;
  }
}

.SideDrawer_Open__Iw8Wi {
  -webkit-transform: translateX(0);
          transform: translateX(0);
}

.SideDrawer_Close__3-2kb {
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
}

.SideDrawer_Logo__2gPu8 {
  height: 11%;
  margin-bottom: 32px;
}

.NavigationItems_NavigationItems__OYHek {
  padding: 0;
  margin: 0;
  list-style-type: none;
  display: flex;
  flex-flow: column;
  align-items: center;
  height: 100%;
}

/* Small tablets */
@media screen and (min-width: 620px) {
  .NavigationItems_NavigationItems__OYHek {
    flex-flow: row;
    width: 50vw;
  }

  .NavigationItems_Participer__3ot5c {
    margin-left: 25px;
  }
}

/* Large tablets & laptops */
@media (min-width: 960px) {
  .NavigationItems_NavigationItems__OYHek {
    width: 45vw;
  }
}

.NavigationItem_NavigationItem__33qxB {
  margin: 10px 0;
  box-sizing: border-box;
  display: block;
  width: 100%;
}

.NavigationItem_NavigationItem__33qxB a {
  color: #2a2f39;
  padding: 10px 20px;
  text-decoration: none;
  width: 100%;
  box-sizing: border-box;
  display: block;
  background: #ebecf0;
  border: 1px solid rgba(255, 255, 255, 0.4);
  box-sizing: border-box;
  box-shadow: 2px 2px 6px #a6abbd, -2px -2px 6px #fafbff;
  border-radius: 7px;
  text-align: center;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.NavigationItem_NavigationItem__33qxB a:hover,
.NavigationItem_NavigationItem__33qxB a:active,
.NavigationItem_NavigationItem__33qxB a.NavigationItem_active__39p-o {
  color: #e6404d;
  font-weight: bold;
  box-shadow: inset 5px 5px 10px #a6abbd, inset -5px -5px 10px #fafbff;
}

/* Small tablets */
@media (min-width: 620px) {
  .NavigationItem_NavigationItem__33qxB {
    margin: 10px 10px;
    padding: 10 30px;
  }
  .NavigationItem_NavigationItem__33qxB a {
    border: none;
    box-shadow: none;
    padding: 10px 0;
  }
  .NavigationItem_NavigationItem__33qxB a:hover,
  .NavigationItem_NavigationItem__33qxB a:active,
  .NavigationItem_NavigationItem__33qxB a.NavigationItem_active__39p-o {
    box-shadow: none;
    font-weight: bold;
    color: #e6404d;
  }
}

.LongButton_LongButton__Y2Lxb {
  font-family: inherit;
  font-size: 0.9em;
  font-weight: bold;
  padding: 5px 15px;
  outline: none;
  background: #ebecf0;
  border: 1px solid rgba(255, 255, 255, 0.4);
  box-sizing: border-box;
  box-shadow: 2px 2px 6px #a6abbd, -2px -2px 6px #fafbff;
  border-radius: 4px;
  cursor: pointer;
}
.LongButton_LongButton__Y2Lxb:active {
  box-shadow: inset 1px 1px 2px #babecc, inset -1px -1px 2px #fff;
}

